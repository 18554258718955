<template>
    <div>
      <h1 class="heading">Partner Hotels</h1>
      <v-data-table
          :headers="hotelsHeader"
          :items="hotels"
          class="elevation-1"
          hide-default-footer
      >
        <template v-slot:body="{ items }">
          <tbody v-if='items.length'>
          <tr v-for="(item, index) in items" :key="index">
            <td class="d-block d-sm-table-cell">{{ item.id }}</td>
            <td class="d-block d-sm-table-cell">{{ item.name }}</td>
            <td class="d-block d-sm-table-cell">{{ hotelEmail(item.owner_id) }}</td>
            <td class="d-block d-sm-table-cell">{{ tariffNumeric[item.tariff_id] }}</td>
            <td class="d-block d-sm-table-cell">
              <v-menu offset-y open-on-hover bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark outlined small v-bind="attrs" v-on="on">
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="$store.state.profile.id !== item.owner_id&&checkVerify(item.owner_id)" link @click="loginAsOwner(item.owner_id)">
                    <v-list-item-title
                    >Login as</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link @click="openEditHotel(item)">
                    <v-list-item-title style="cursor: pointer"
                    >Edit Tariff</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link @click="downloadJSON(item.id)">
                    <v-list-item-title style="cursor: pointer"
                    >Download JSON</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link>
                    <v-list-item-title style="cursor: pointer"
                    >

                      <label :for="item.id" style="cursor: pointer"
                      >Upload JSON
                      </label>
                      <input
                          type="file"
                          :id="item.id"
                          style="display: none"
                          v-on:change="setJson"
                      />
                    </v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link>
                    <v-list-item-title style="cursor: pointer"
                    >Download images</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link @click="openCloneModal(item)">
                    <v-list-item-title style="cursor: pointer"
                    >Clone</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item link @click="deleteHotelOpen(item.id)">
                    <v-list-item-title style="cursor: pointer;color:red;"
                    >Delete</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td style="text-align: center" colspan="5">You don't have hotels yet, it's time to create them</td>
          </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-dialog
          v-model="editHotelModal"
          max-width="500px"
          id="popup-edit-hotel"
          class="popup-registration popup mfp-hide"
      >
        <div class="popup__form popup__content">
          <v-icon
              style="top: 10px; right: 10px; position: absolute"
              class="close__modal"
              @click="editHotelModal = false"
          >mdi-close</v-icon
          >
          <v-form
              class="form"
              style="
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
              v-if="editHotel"
              ref="form_edit_user"
              @submit="editHotelConfirm"
              novalidate
          >
            <h2 class="popup__title" style="margin-bottom: 15px;">Edit Tariff</h2>
            <div class="form__item">
              <div class="form__control">
                <v-select
                    solo
                    outlined
                    required
                    dense
                    v-model="editHotel.tariff_id"
                    item-text="name"
                    item-value="value"
                    flat :items="tarrifArr">

                  <template v-slot:selection="{ item }">
                    <div style="display: flex;justify-content: space-between;width: 100%;">
                      <div>{{ item.name }}</div><div v-if="item.value !== 1"> Slots: {{item.tickets}}</div>
                    </div>
                  </template>
                  <template v-slot:item="{ item }">
                    <div style="display: flex;justify-content: space-between;width: 100%;">
                      <div>{{ item.name }}</div><div v-if="item.value !== 1"> Slots: {{item.tickets}}</div>
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
            <div
                style="
              display: flex;
              margin-top: 15px;
              justify-content: flex-end;
              align-items: center;
              width: 100%;
            "
            >
              <v-btn color="primary"
                     outlined
                     small type="submit" class="btn_form">
                Edit
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-dialog>
      <v-dialog
          v-model="cloneHotelModal"
          max-width="500px"
          id="popup-clone-hotel"
          class="popup-registration popup mfp-hide"
      >
        <div class="popup__form popup__content">
          <v-icon
              style="top: 10px; right: 10px; position: absolute"
              class="close__modal"
              @click="cloneHotelModal = false"
          >mdi-close</v-icon
          >
          <v-form
              class="form"
              style="
            display: flex;
            flex-direction: column;
            justify-content: center;
          "
              v-if="users.length"
              ref="form_edit_user"
              @submit="cloneUserConfirm"
              novalidate
          >
            <h2 class="popup__title" style="margin-bottom: 15px;">Clone hotel</h2>
            <div class="form__item">
              <div class="form__control">
                <v-select
                    solo
                    :error="error_clone"
                    :error-messages="error_clone_message"
                    outlined
                    required
                    dense
                    v-model="clonedUserID"
                    item-text="name"
                    item-value="id"
                    flat :items="usersFiltered">
                  <template v-slot:selection="{ item }">
                    <div>
                      {{ item.name }}
                    </div>
                  </template>
                  <template v-slot:item="{ item }">
                    <div>
                      {{ item.name }}
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
            <div
                style="
              display: flex;
              margin-top: 15px;
              justify-content: flex-end;
              align-items: center;
              width: 100%;
            "
            >
              <v-btn color="primary"
                     outlined
                     small type="submit" class="btn_form">
                Clone
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-dialog>
      <PopupSuccess ref="PopupSuccess" title="Success" :content="successMessage"/>
      <PopupDelete ref="PopupDeleteHotel" title="Are you sure about delete hotel?" @confirm="deleteHotelConfirm"/>
    </div>
</template>

<script>
  import AgencyService from "../services/agency.service";
  import titleMixin from "../mixins/titleMixin";
  export default {
    mixins:[titleMixin],
    title(){
      return "Partner Hotels"
    },
    name: "HotelsPartners",
    components:{
      PopupSuccess:() => import("@/components/Popup/PopupSuccess"),
      PopupDelete:() => import("@/components/Popup/PopupDelete"),
    },
    data(){
      return{
        cloneHotelModal:false,
        clonedUserID:null,
        clonedHotelID:null,
        payments:[],
        tariffNumeric:{
          1:'Free',
          2:'Standart',
          3:'Profi'
        },
        editHotel:null,
        registerUser: {
          name: null,
          email: null,
          password: null,
          is_agency:0,
        },
        editUserModal:false,
        editUser:null,
        dialogUser:false,
        nameRules: [(v) => !!v || this.$t('landing.valid["Имя обязателено"]')],
        passwordRules: [
          (v) => !!v || this.$t('landing.valid["Пароль обязателен"]'),
          (v) =>
            (v && v.length >= 6) ||
            this.$t(
              'landing.valid["Пароль должен содержать не менее 6 символов"]'
            ),
          (v) =>
            (v && v.length < 30) ||
            this.$t("form['Пароль должен содержать не более 30 символов']"),
        ],
        emailRules: [
          (v) => !!v || this.$t('landing.valid["E-mail обязателен"]'),
          (v) =>
            /.+@.+\..+/.test(v) ||
            this.$t('landing.valid["E-mail должен быть валидным"]'),
        ],
        users:[],
        hotels:[],
        valid_register: true,
        hotelsHeader:[
          { text: "ID", align: "left", value:"id", sortable: true },
          { text: "Name", align: "left", value:"name", sortable: true },
          { text: "Email", align: "left", value:"email",sortable: true },
          { text: "Tariff", align: "left" },
          { text: "Options", align: "left" },
        ],
        error_register_message:'',
        error_register:false,
        successMessage:"",
        deleteUserID:null,
        deleteHotelID:null,
        editHotelModal:false,
        usersFiltered:[],
        availableSlots:[],
        error_clone:false,
        error_clone_message:"",
        allSlotsCount:[]
      }
    },
    async mounted(){
      this.hotels = await AgencyService.getHotels();
      this.users = await AgencyService.getUsers();
    },
    computed:{
      tarrifArr:function () {
        const arr = [
          {name:"Free",value:1},
        ];
        const candidateStandart = this.availableSlots.find(x => x.plan_id === 2);
        if(candidateStandart){
          const obj = {
            name:"Standart",
            value:2,
            disabled:!candidateStandart.quantity,
            tickets:candidateStandart.quantity
          };
          arr.push(obj)
        }else{
          const obj = {
            name:"Standart",
            value:2,
            disabled:true,
            tickets:0
          }
          arr.push(obj)
        }
        const candidateProfi = this.availableSlots.find(x => x.plan_id === 3);
        if(candidateProfi){
          const obj = {
            name:"Profi",
            value:3,
            disabled:!candidateProfi.quantity,
            tickets:candidateProfi.quantity
          }
          arr.push(obj)
        }else{
          const obj = {
            name:"Profi",
            value:3,
            disabled:true,
            tickets:0
          }
          arr.push(obj)
        }
        return arr;
      }
    },
    methods:{
      checkVerify(id){
        return !!this.users.find(x => x.id === id);
      },
      async loginAsOwner(user_id){
        const data = await AgencyService.loginAsOwner(user_id);
        localStorage.removeItem('token');
        window.open(`${process.env.VUE_APP_LINK}/?admin_token=${data.agency_token}`,'_self');
      },
      openCloneModal(hotel){
        this.clonedHotelID = hotel.id;
        this.usersFiltered = [];
        if(this.$store.state.profile.id === hotel.owner_id){
          this.usersFiltered = JSON.parse(JSON.stringify(this.users));
        }else{
          this.usersFiltered = JSON.parse(JSON.stringify(this.users.filter(x => x.id !== hotel.owner_id)))
        }
        this.cloneHotelModal = true;
      },
      async cloneUserConfirm(e){
        e.preventDefault();
        this.error_clone_message = "";
        this.error_clone = false;
        const payload = {
          hotel_id:this.clonedHotelID,
          user_id:this.clonedUserID
        };
        const data = await AgencyService.cloneHotel(payload)
        if(data?.id){
          this.hotels.push(data);
          this.successMessage = "Hotel cloned successfully!";
          this.cloneHotelModal = false;
          this.$refs.PopupSuccess.open();
          this.availableSlots = await AgencyService.getAgencySlots(this.$store.state.profile.id);
        }else{
          this.error_clone_message = data.message;
          this.error_clone = true;
        }
      },
      async setJson(e) {
        let files = e.target.files || e.dataTransfer.files;
        const payload = {
          hotel_id:e.target.id,
          file:files[0]
        };
        await AgencyService.uploadHotelData(payload);
        this.successMessage = 'Hotel data refreshed successfully!';
        this.$refs.PopupSuccess.open();
      },
      async downloadJSON(hotel_id){
        const candidate = this.hotels.find((x) => x.id === hotel_id);
        const data = await AgencyService.downloadHotelData(hotel_id);
        if (candidate) {
          const blob = new Blob([JSON.stringify(data)], { type: "text/json" });
          const link = document.createElement("a");
          link.download =
            candidate.name.replaceAll(" ", "_") +
            "_" +
            this.$moment().format("DD_MM_yyyy_HH_mm") +
            ".json";
          link.href = window.URL.createObjectURL(blob);
          link.dataset.downloadurl = ["text/json", link.download, link.href].join(
            ":"
          );
          const evt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
          });
          link.dispatchEvent(evt);
          link.remove();
        }
      },
      openEditHotel(hotel){
        this.editHotel = JSON.parse(JSON.stringify(hotel));
        this.editHotelModal = true;
      },
      hotelEmail(user_id){
        if(this.$store.state.profile.id === user_id){
          return this.$store.state.profile.email
        }else{
          const candidate = this.users.find(x => x.id === user_id);
          if(candidate){
            const candidateIndex = this.users.indexOf(candidate);
            if(candidateIndex>=0){
              return this.users[candidateIndex].email;
            }else{
              return ""
            }
          }else{
            return ""
          }
        }
      },
      async editHotelConfirm(e){
        e.preventDefault();
        const data = await AgencyService.editHotel(this.editHotel);
        if(data[0].id){
          const candidate = this.hotels.find(x => x.id === data[0].id);
          if(candidate){
            const candidateIndex = this.hotels.indexOf(candidate);
            if(candidateIndex>=0){
              this.hotels.splice(candidateIndex,1,data[0]);
              this.successMessage  = 'Hotel edited successfully!';
              this.editHotelModal = false;
              this.$refs.PopupSuccess.open();
              this.availableSlots = await AgencyService.getAgencySlots(this.$store.state.profile.id);
            }
          }
        }

      },
      async deleteHotelOpen(hotel_id){
        this.deleteHotelID = hotel_id;
        this.$refs.PopupDeleteHotel.open();
      },
      async deleteHotelConfirm(){
        const data = await AgencyService.deleteHotel(this.deleteHotelID);
        if(data.id){
          const candidate = this.hotels.find(x => x.id === data.id);
          if(candidate){
            const candidateIndex = this.hotels.indexOf(candidate);
            if(candidateIndex>=0){
              this.hotels.splice(candidateIndex,1);
            }
          }
        }
      },
    }
  }
</script>

<style scoped>

</style>
